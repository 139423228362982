import { Divider, ScrollArea, Stack, Text, useMantineTheme } from '@mantine/core'
import { IconExclamationCircle } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useErrorMessages } from '@/constants/content'

import { ToggleDetails } from './Airdrop/StartAirdrop'
import CopyButton from './Buttons/CopyButton'

interface ErrorMessageProps {
  title?: string
  message: string | JSX.Element
  details?: string | JSX.Element
  fullWidth?: boolean
  bug?: boolean
  report?: boolean
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  title,
  message,
  details,
  fullWidth = false,
  bug = false,
  report = false,
}) => {
  const { t } = useTranslation()
  title = title ?? t('common.error', 'Error')
  message = message ?? t('common.error', 'Error')

  const theme = useMantineTheme()
  const { reportMessage, bugFoundMessage } = useErrorMessages()
  const [openToggle, setOpenToggle] = useState<boolean>(false)

  const toggleErrorDetails = useCallback(() => {
    setOpenToggle(!openToggle)
  }, [openToggle])

  return (
    <Stack
      style={{
        width: fullWidth ? '100%' : 320,
        gap: '8px',
        padding: '10px',
        backgroundColor: theme.colors.background2[0],
        borderRadius: 8,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Stack style={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
        <IconExclamationCircle style={{ color: theme.colors.notification[0] }} size="16px" />
        <Text
          style={{
            color: theme.colors.notification[0],
            fontFamily: 'monument-grotesk-bold',
            paddingTop: '2px',
          }}
        >
          {title}
        </Text>
      </Stack>
      <Divider />
      <Text
        style={{
          color: theme.colors.title[0],
          fontFamily: 'monument-grotesk-bold',
        }}
      >
        {message}
      </Text>
      {report && (
        <Text style={{ maxHeight: '150px', width: 'fit-content', fontSize: '16px' }}>
          {bug ? bugFoundMessage : reportMessage}
        </Text>
      )}
      {details && (
        <ToggleDetails
          isError
          title={
            <Stack style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
              <Text
                style={{
                  color: theme.colors.title[0],
                  fontFamily: 'monument-grotesk-bold',
                }}
              >
                Details:
              </Text>
              {openToggle && <CopyButton value={`Type: ${message} | Details: ${details}`} />}
            </Stack>
          }
          onClick={toggleErrorDetails}
          open={openToggle}
        >
          <ScrollArea
            h="100px"
            type="hover"
            scrollbarSize={4}
            style={{
              backgroundColor: theme.colors.popUpOutline[0],
              borderRadius: 8,
              padding: 10,
              margin: '10px 0',
              position: 'relative',
            }}
          >
            <Text
              style={{
                overflowWrap: 'anywhere',
                maxHeight: '100px',
                width: 'fit-content',
                fontSize: '16px',
              }}
            >
              {details}
            </Text>
          </ScrollArea>
        </ToggleDetails>
      )}
    </Stack>
  )
}

export default ErrorMessage
