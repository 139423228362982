import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import {
  // AIRDROP_CONTRACT_ADDRESS,
  BRIDGE_L1_NATIVE_HOME_ADDRESS,
  // LAMINA1_PASS_ADDRESS,
  LL1_ADDRESS,
  STAKE_ADDRESS,
} from '@/constants/blockchain'
import { useChainFunctions } from '@/hooks/useChain'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'

type WithChildren = {
  children?: ReactNode
}

type FeatureString = 'll1' | 'governance' | 'airdrop' | 'bridge' | 'lamina1pass'
type Feature = {
  name: FeatureString
  contractAddress: `0x${string}`
  deployed?: boolean
}

// Features to check for
const supportedFeatures: Feature[] = [
  {
    name: 'll1',
    contractAddress: LL1_ADDRESS,
  },
  {
    name: 'governance',
    contractAddress: STAKE_ADDRESS,
  },
  {
    name: 'airdrop',
    // contractAddress: AIRDROP_CONTRACT_ADDRESS,
    contractAddress: '0x0000000000000000000000000000000000001234',
  },
  {
    name: 'bridge',
    contractAddress: BRIDGE_L1_NATIVE_HOME_ADDRESS,
  },
  {
    name: 'lamina1pass',
    // contractAddress: LAMINA1_PASS_ADDRESS,
    contractAddress: '0x0000000000000000000000000000000000001234',
  },
]

type ChainFeaturesContextType = {
  isLoading: boolean
  hasFeature: (feature: FeatureString) => boolean
}

export const ChainFeaturesContext = createContext<ChainFeaturesContextType>({
  isLoading: false,
  hasFeature: () => false,
})

export const ChainFeaturesProvider: FC<WithChildren> = ({ children }) => {
  const { hasCode } = useChainFunctions(l1NativeWagmiConfig)

  const [features, setFeatures] = useState<Feature[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    const checkContracts = async () => {
      try {
        const results = await Promise.allSettled(
          supportedFeatures.map(feature => hasCode(feature.contractAddress))
        )
        setFeatures(
          results.map((result, index) => ({
            name: supportedFeatures[index].name,
            contractAddress: supportedFeatures[index].contractAddress,
            deployed: result.status === 'fulfilled' && result.value,
          }))
        )
        setIsLoading(false)
      } catch (e) {
        setFeatures([])
      }
    }
    checkContracts()
  }, [hasCode])

  const contextValue = useMemo(
    () => ({
      isLoading,
      hasFeature: (feature: string) => features.find(f => f.name === feature)?.deployed || false,
    }),
    [features]
  )

  return (
    <ChainFeaturesContext.Provider value={contextValue}>{children}</ChainFeaturesContext.Provider>
  )
}

export const useChainFeaturesContext = () => useContext(ChainFeaturesContext)
