import { Grid, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { IconChevronLeft, IconLink } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useErrorMessages } from '@/constants/content'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useAuth } from '@/plugins/auth'
import {
  getDefaultBannerImage,
  getDefaultProfileImage,
} from '@/plugins/auth/AuthProvider/effects/functions'
import { baseAddressUrl } from '@/utils/table'
import {
  openWebsite,
  ProfileUploadMode,
  reportProfile,
  shortenHash,
  startsWithIpfsOrNot,
  StoredTheme,
  twitterShareLink,
} from '@/utils/utils'

import RootButton from '../Buttons/RootButton'
import DisplayUsername from '../DisplayUsername'
import getSocialMessages from '../Items/socials'
import ModalSkeleton from '../Modals/ModalSkeleton'
import EditProfile from '../Modals/Profiles/EditProfile'
import TipProfile from '../Modals/Profiles/TipProfile'
import OptionsMenu from '../OptionsMenu'
import { ProfileMetadata } from './metadata'
import classes from './ProfileHeader.module.css'

export const BANNER_WIDTH = 1300
export const BANNER_HEIGHT = 201
export const PROFILE_WIDTH = 350
export const PROFILE_HEIGHT = 350

export const displayImage = (defaultImage: string, image: string) =>
  image ? startsWithIpfsOrNot(image) : defaultImage

interface ProfileHeaderProps {
  profileAddress: string
  profileUsername: string
  profile: ProfileMetadata
  setProfile: (profile: ProfileMetadata) => void
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  profileAddress,
  profileUsername,
  profile,
  setProfile,
}) => {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { address } = useAuth()
  const errorMessages = useErrorMessages()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const truncate = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)

  const { t } = useTranslation()
  const isMyProfile = useMemo(
    () => address && profileAddress.toLowerCase() === address.toLowerCase(),
    [profileAddress, address]
  )
  const [openedEdit, { open: openEdit, close: closeEdit }] = useDisclosure(false)
  const [openedTip, { open: openTip, close: closeTip }] = useDisclosure(false)

  // default profile images
  const [userTheme] = useLocalStorage<StoredTheme>('userTheme')
  const defaultPfp = useMemo(() => getDefaultProfileImage(userTheme?.isLightMode), [userTheme])
  const defaultBannerImg = useMemo(() => getDefaultBannerImage(userTheme?.isLightMode), [userTheme])

  const [uploadModeOn, setUploadModeOn] = useState<ProfileUploadMode>({
    profile: false,
    banner: false,
  })

  // To ensure it loads userIdentity on web3auth
  const userIdentity = useMemo(() => {
    const res = localStorage.getItem(`userIdentity-${address}`)
    if (res) {
      try {
        return JSON.parse(res)
      } catch {
        return undefined
      }
    }
    return undefined
  }, [address])

  // Replace last part of the url (address) with the profile username
  const shareableLink = `${window.location}`.replace(/[^/]*$/, profileUsername || profileAddress)
  const { twitterProfileMessage, discordProfileMessage, twitterAccountTag } = useMemo(
    () =>
      getSocialMessages(
        t,
        shareableLink,
        profileUsername ? `@${profileUsername}` : shortenHash(profileAddress)
      ),
    [t, userIdentity, profileAddress, shareableLink]
  )
  // Discord sharing Info
  const discordSharing = useMemo(
    () => ({
      shareMessage: discordProfileMessage,
      successMessage: t(
        'components.profile.sharedDiscordSuccess',
        'Profile successfully shared in #identity on the LAMINA1 Discord.'
      ),
      errorMessage: errorMessages.profileDiscordSharing,
      channelWebhook: process.env.WEBHOOK_DISCORD_PROFILE || '',
    }),
    [discordProfileMessage, errorMessages.nftDiscordSharing, t]
  )

  const UserDetails = useMemo(
    () => (
      <Stack style={{ alignItems: 'flex-start', gap: 2 }}>
        <Title
          order={isMobile ? 3 : 2}
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {profile?.name || t('components.profile.noName', 'No Name')}
        </Title>
        <DisplayUsername truncate={truncate} address={profileAddress} username={profileUsername} />
      </Stack>
    ),
    [profile, profileAddress, profileUsername, t, truncate]
  )

  const ButtonComponent = useMemo(
    () => (
      <Stack style={{ flexDirection: 'row', gap: isMobile ? '0' : '10px', alignItems: 'center' }}>
        {address && userIdentity && (
          <>
            {isMyProfile ? (
              <Grid style={{ flexDirection: 'row', gap: 0 }}>
                <Grid.Col span="content">
                  {userIdentity.username ? (
                    <RootButton style1={!isMobile} onClick={openEdit}>
                      {t('buttons.editProfile', 'Edit Profile')}
                    </RootButton>
                  ) : (
                    <RootButton
                      style1={!isMobile}
                      onClick={() =>
                        navigate('/freeusername', {
                          state: {
                            username: userIdentity.username,
                            pathname: '/profile',
                          },
                        })
                      }
                    >
                      {t('buttons.createProfile', 'Create Profile')}
                    </RootButton>
                  )}
                </Grid.Col>
                <Grid.Col span="content">
                  {userIdentity.username && (
                    <RootButton
                      secondary
                      style1={!isMobile}
                      onClick={() =>
                        navigate('/premiumusername', {
                          state: {
                            username: userIdentity.username,
                            pathname: '/profile',
                          },
                        })
                      }
                    >
                      {t('components.settings.identity.updateUsername', 'Update Username')}
                    </RootButton>
                  )}
                </Grid.Col>
              </Grid>
            ) : (
              <RootButton style1={!isMobile} onClick={openTip}>
                {t('buttons.sendTip', 'Send Tip')}
              </RootButton>
            )}
          </>
        )}
        <OptionsMenu
          copyLink={shareableLink}
          twitterSharelink={twitterShareLink(
            shareableLink,
            twitterProfileMessage,
            twitterAccountTag
          )}
          discordSharing={discordSharing}
          explorerLink={`${baseAddressUrl}${profileAddress}`}
        >
          <a onClick={reportProfile} className={classes.options}>
            {t('components.profile.reportProfile', 'Report Profile')}
          </a>
        </OptionsMenu>
      </Stack>
    ),
    [
      isMobile,
      address,
      isMyProfile,
      openEdit,
      openTip,
      profileAddress,
      profileUsername,
      t,
      theme,
      userIdentity,
      navigate,
    ]
  )

  return (
    <Stack
      style={{
        minHeight: isMobile ? 'fit-content' : '486px',
        backgroundColor: isMobile ? theme.colors.background[0] : theme.colors.background2[0],
        position: isMobile ? 'initial' : 'relative',
        borderRadius: isMobile ? '0px' : '12px',
      }}
    >
      <Stack
        style={{
          backgroundImage: `url(${displayImage(defaultBannerImg, profile?.bannerImage)})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: BANNER_HEIGHT,
          borderRadius: isMobile ? '0' : '12px 12px 0 0',
        }}
      />
      <Stack style={{ margin: '0px 15px', position: isMobile ? 'initial' : 'absolute', top: 180 }}>
        <Stack
          style={{
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Stack style={{ flexDirection: 'row', gap: isMobile ? '16px' : '25px' }}>
            <img
              className={classes.userCardImage}
              style={{
                borderRadius: theme.radius.md,
                height: isMobile ? '100px' : '170px',
                width: isMobile ? '100px' : '170px',
                border: `2px solid ${theme.colors.profileOutline[0]}`,
              }}
              src={displayImage(defaultPfp, profile?.image)}
              alt="profile"
            />
            <Stack
              style={{
                justifyContent: isMobile ? 'flex-start' : 'flex-end',
                marginBottom: isMobile ? '5px' : '10px',
                gap: '5px',
              }}
            >
              {UserDetails}
              {ButtonComponent}
            </Stack>
          </Stack>
        </Stack>
        <Stack style={{ gap: '1px' }}>
          <Text
            style={{
              textAlign: 'left',
              color: theme.colors.paragraph[0],
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}
          >
            {(profile?.description as String) ||
              t('components.profile.noDescription', 'No Description')}
          </Text>
          {profile?.creator?.link && (
            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: 5,
                alignItems: 'center',
              }}
            >
              <IconLink
                size={20}
                style={{ transform: 'rotate(45deg)', color: theme.colors.paragraph[0] }}
              />
              <a
                className={classes.user}
                onClick={() => window.open(openWebsite(profile.creator.link), '_blank')}
              >
                {profile.creator.link}
              </a>
            </Stack>
          )}
        </Stack>
        <ModalSkeleton
          opened={openedEdit}
          size={!isMobile ? '610px' : 'md'}
          onClose={() => {
            setUploadModeOn({ banner: false, profile: false })
            closeEdit()
          }}
          title={
            <Stack style={{ flexDirection: 'row', gap: 20, alignItems: 'center' }}>
              <Title
                order={4}
                style={{
                  color:
                    uploadModeOn.profile || uploadModeOn.banner ? theme.colors.textAndIcon[0] : '',
                  cursor: 'pointer',
                }}
                onClick={() => setUploadModeOn({ banner: false, profile: false })}
              >
                {t('components.profile.modal.editProfile', 'Edit Profile')}
              </Title>
              {(uploadModeOn.profile || uploadModeOn.banner) && (
                <IconChevronLeft
                  style={{
                    color: theme.colors.textAndIcon[0],
                    cursor: 'pointer',
                  }}
                  size={25}
                  onClick={() => setUploadModeOn({ banner: false, profile: false })}
                />
              )}
              {(uploadModeOn.profile || uploadModeOn.banner) &&
                (uploadModeOn.profile ? (
                  <Title order={4}>{t('components.profile.modal.avatar', 'Avatar')}</Title>
                ) : (
                  <Title order={4}>{t('components.profile.modal.banner', 'Banner')}</Title>
                ))}
            </Stack>
          }
        >
          <EditProfile
            profile={profile}
            setProfile={setProfile}
            closeEdit={closeEdit}
            uploadModeOn={uploadModeOn}
            setUploadModeOn={setUploadModeOn}
          />
        </ModalSkeleton>
        <ModalSkeleton
          opened={openedTip}
          onClose={closeTip}
          title={t('components.profile.modal.sendTip', 'Send a Tip')}
          size={!isMobile ? '610px' : 'md'}
        >
          <TipProfile
            onCancel={closeTip}
            profile={profile}
            profileAddress={profileAddress}
            profileUsername={profileUsername}
          />
        </ModalSkeleton>
      </Stack>
    </Stack>
  )
}

export default ProfileHeader
